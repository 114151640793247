<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vona-logo />
        <h2 class="brand-text text-primary ml-1">
          {{ getAssociation }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            Bem-vindo ao Emplacamento! 👋
          </b-card-title>

          <!-- form -->
          <validation-observer ref="loginForm">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="E-mail" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="E-mail"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    data-cy="login"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small
                    data-cy="invalidUserNotification"
                    class="text-danger"
                    >{{ errors[0] }}</small
                  >
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Senha</label>
                  <b-link
                    :to="{ name: 'auth-forgot-password', query: $route.query }"
                  >
                    <small>Esqueceu a Senha?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Senha"
                  vid="senha"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      data-cy="password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    data-cy="invalidPasswordNotification"
                    class="text-danger"
                    >{{ errors[0] }}</small
                  >
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="rememberMe"
                  name="checkbox-1"
                >
                  Lembre de mim
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <av-button
                class="primary-association"
                data-cy="submit"
                type="submit"
                variant="primary"
                block
                :loading="waiting"
              >
                Logar
              </av-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VonaLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardTitle,
  BImg,
  BForm,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import { AvButton } from "@/components";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { getHomeRouteForLoggedInUser } from "@/services/utils";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import appSSO from "@/services/appSSO";

// Services
import { useAuth, useCache } from "@/services";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardTitle,
    BImg,
    BForm,
    BAlert,
    VonaLogo,
    AvButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      rememberMe: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-car-v2.svg"),
      domain: { label: null, value: null },
      waiting: false,

      // validation rules
      required,
      email,
    };
  },
  created() {
    const queryParams = this.$route.query;
    const isSocialLogin = appSSO.checkParamsContainSocialLogin(queryParams);
    appSSO.setSocialLogin(isSocialLogin);

    if (appSSO.checkUserIsAuthenticate()) {
      if (isSocialLogin) {
        this.socialLoginTreatment();
      } else {
        this.showLoading();
        this.getUserInfo();
      }
    }

    if (this.isStorageNotificarLogout()) {
      localStorage.removeItem(useAuth.serviceConfig.storageNotificarLogout);

      this.$toast({
        component: ToastificationContent,
        props: {
          title: "A sua sessão expirou. Por favor, faça login novamente.",
          icon: "CheckIcon",
          variant: "danger",
        },
      });
    }
  },
  methods: {
    login() {
      this.waiting = true;
      this.$refs.loginForm
        .validate()
        .then((success) => {
          if (success) {
            this.$store
              .dispatch("authentication/fetchLogin", {
                UserName: this.userEmail,
                Password: this.password,
                Domain: localStorage.getItem("domain"),
              })
              .then(async () => {
                if (appSSO.socialLogin) {
                  this.socialLoginTreatment();
                } else {
                  this.getUserInfo();
                }
              })
              .catch((error) => {
                // Verificando se o usuário precisa mudar a senha
                if (
                  error.response.status == 400 &&
                  error.response.data.errors[0].key == "0x0A0002"
                ) {
                  this.$store.commit(
                    "admin-users/SET_EMAIL_RECOVER",
                    this.userEmail
                  );
                  this.$store.commit(
                    "admin-users/SET_CODE_RECOVER",
                    this.password
                  );
                  return this.$router.push({
                    name: "/reset-password",
                    query: this.$route.query,
                  });
                }

                const mensage = {
                  email: [error.response.data.errors[0].value],
                  password: [""],
                };

                this.$refs.loginForm.setErrors(mensage);
                this.waiting = false;
              });
          }
        })
        .catch(() => {
          this.waiting = false;
        });
    },
    async getUserInfo() {
      const respUser = await useCache.requestGet();
      const userData = await this.$store.dispatch("app-user/fetchSetUserInfo", {
        ctx: this,
        data: respUser.data.Data,
      });

      // Load System Paramsz
      await this.$store.dispatch("appConfig/fetchLoadSystemParameters");

      this.waiting = false;

      let parameters = this.$route.query;
      let url;

      if (parameters.redirectTo != null) {
        url = parameters.redirectTo;
      } else {
        url = getHomeRouteForLoggedInUser(userData.role);
      }

      // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
      this.$router.replace(url).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Bem-vindo ${userData.fullName || userData.username}`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `Você fez login com sucesso como ${userData.role}. Agora você pode começar a explorar!`,
          },
        });
      });
    },
    async socialLoginTreatment() {
      const response = await useAuth.changeToken(
        appSSO.config.systems.emplacamentoPortal
      );

      const queryParams = appSSO.getUrlParams();

      if (response.data.token) {
        appSSO.redirectUserToSocialLogin(queryParams, response.data.token);
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Login Social",
            text: "Não foi possível realizar login social, entre em contato com o administrador",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    showLoading() {
      const loadingBg = document.getElementById("loading-bg");
      if (loadingBg) {
        loadingBg.style.display = "block";
        loadingBg.style.zIndex = "999";
      }
    },
    isStorageNotificarLogout() {
      return (
        localStorage.getItem(useAuth.serviceConfig.storageNotificarLogout) ===
        "true"
      );
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      this.sideImg = require("@/assets/images/pages/login-car-v2.svg");

      if (this.getAssociation != "Vona") {
        this.sideImg = require("@/assets/images/pages/login-car-v2-" +
          this.getAssociation +
          ".svg");
      }

      return this.sideImg;
    },
    getAssociation() {
      return localStorage.getItem("domain") || "Vona";
    },
  },
  watch: {
    domain(newDomain) {
      if (process.env.VUE_APP_ENVIRONMENT == "develop") {
        this.$store.dispatch("authentication/setDomain", newDomain);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/pages/page-auth.scss";

.spin {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
